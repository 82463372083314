import React from "react";
import { Container, Col, Row } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

// styles

const Footercontent = styled.div`
    text-align: center;
    color: ${({ theme }) => theme.colours.common.white};

    & a {
      color: ${({ theme }) => theme.colours.common.white};
      text-decoration: none;
    }

    & a:hover {
      color: ${({ theme }) => theme.colours.primary.main};
      
    }
`


function Sitefooter() {
  
  const data = useStaticQuery(graphql`
  query footer {
    directus {
      concut_settings {
        site_title
        site_description
        site_author
        site_logo {
          title
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(
                width: 300
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        footer_content
      }
      concut_home {
        seo_title
        hero_image {
          title
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        center_content
        right_content
      }
    }
  }
  `)

  const sitelogo = getImage(data.directus.concut_settings.site_logo.imageFile);

  
  return (
    <>
      <Container>
       <Row className="footerRow">
          <Col xs={12} md={12} lg={4}>
            <div className="logo"><GatsbyImage image={sitelogo} className="logo" alt="Concut NSW" objectPosition="center top" /></div>
          </Col>
          <Col xs={12} md={12} lg={4}>
            <div dangerouslySetInnerHTML={{
                __html: data.directus.concut_home.center_content,
            }} />
          </Col>
          <Col xs={12} md={12} lg={4}>
            <div dangerouslySetInnerHTML={{
                __html: data.directus.concut_home.right_content,
            }} />
          </Col>
        </Row>
        <Row>
        <div className="footerCopy" dangerouslySetInnerHTML={{
                __html: data.directus.concut_settings.footer_content,
            }} />
        </Row>
      </Container>
  </>
  );
}

export default Sitefooter;